import { BRANDS } from '@cof/plastic-components';
import config from '@cof/ukweb-config/lib/clientConfig';
import localStorageUtils from './localStorage';
import errorCodeMap from './errorCodeMap';
import appendBrandParam from './appendBrandParam';

const buttonSubmission = (e, lastPinDigit) => {
    if (!lastPinDigit.value) {
        e.preventDefault();
        return true;
    }
    return false;
};

const onChangeEventForRadio = (e, setCheckedNumber) => {
    const { value } = e.target;
    setCheckedNumber(value);
};

const makeRequestHeaders = () => ({
    'x-csrf-token': localStorageUtils.get('x-csrf-token'),
    'datetime': String(new Date())
});

const setCSRF = response => {
    if (response.headers['x-csrf-token']) {
        localStorageUtils.set('x-csrf-token', response.headers['x-csrf-token']);
    }
    return response;
};

const getEncryptionKey = response => {
    let encryptionKey;
    const keys = response.data?.key;
    if (keys) {
        // Check for JWKS
        if (Array.isArray(keys)) {
            const filteredKeys = keys.filter(
                key => key.kid === 'auth-enc' && key.use === 'enc'
            );
            // eslint-disable-next-line prefer-destructuring
            encryptionKey = filteredKeys[0];
        }
        // Check for JWK
        else if (keys.kid === 'auth-enc' && keys.use === 'enc') {
            encryptionKey = keys;
        }
    }
    return encryptionKey;
};

const errorHandling = (
    error,
    setCurrentModule,
    setAdditionalData,
    additionalData,
    navigate,
    brand,
    chain
) => {
    if (error?.response?.data?.errorCode === 'TEMPORARILY_LOCKED') {
        navigate(appendBrandParam(brand, '/lockout'));
    }
    setCurrentModule(
        errorCodeMap(error?.response?.data?.errorCode, chain).stage
    );

    // Re-sets encryption key
    if (chain === 'forgotUsername') {
        setAdditionalData({
            hasError: true,
            errorMessage: errorCodeMap(error?.response?.data?.errorCode)
                .message,
            cpa: {
                encryptionKey: additionalData.cpa.encryptionKey
            }
        });
    } else {
        setAdditionalData({
            sessionToken: additionalData.sessionToken,
            hasError: true,
            errorMessage: errorCodeMap(error?.response?.data?.errorCode).message
        });
    }
};

const organismProps = (
    currentModule,
    additionalData,
    submissionFunction,
    navigate,
    brand,
    setCurrentModule,
    setAdditionalData,
    enterActivationCodeApi,
    disabled,
    setDisabled,
    orchHandler
) => {
    switch (currentModule) {
        case 'OtpModule2':
            return {
                onSubmit: submissionFunction,
                disabled,
                setDisabled,
                phoneNumbers: additionalData.phoneNumberData
            };

        case 'OtpModule3':
            return {
                onSubmit: submissionFunction,
                disabled,
                setDisabled,
                isFinalAttempt:
                    additionalData?.warningCode === 'OTP_FINAL_ATTEMPT',
                // Code below is tested in ecosystems file
                onGetAnotherCode: () => {
                    orchHandler(
                        {
                            authField1: '',
                            authField2: '1',
                            navigate,
                            brand,
                            setCurrentModule,
                            setAdditionalData,
                            currentModule,
                            additionalData
                        },
                        enterActivationCodeApi
                    );
                }
            };

        case 'RetrieveUsernameModule':
            return {
                hasError: additionalData.hasError,
                username: additionalData.username
            };

        default:
            return {
                onSubmit: submissionFunction,
                disabled,
                setDisabled,
                hasError: additionalData.hasError
            };
    }
};

const acceptOnlyNumbersInput = (e, setInputValue, onChange) => {
    const numberPattern = new RegExp(/^[0-9]{1,6}$/);
    if (numberPattern.test(e.nativeEvent.data) || e.nativeEvent.data === null) {
        setInputValue(e.target.value);
        onChange(e.target.value);
    }
};

const acceptOnlyNumericPasteEvent = e => {
    const numbersOnlyPattern = new RegExp('^[0-9]+$');
    if (!numbersOnlyPattern.test(e.clipboardData.getData('Text'))) {
        e.preventDefault();
    }
};

const blurTrackerSetter = (firstOnBlur, setFirstOnBlur) => {
    if (firstOnBlur === false) {
        setFirstOnBlur(true);
    }
};

const getErrorForWarningText = res => {
    return res.data?.warningCode
        ? errorCodeMap(res.data?.warningCode).message
        : undefined;
};

const authOutboundURL = (service, channel) => {
    const { scheme, host, port } = config.get('wsOrchestration');
    const channelParam = channel ? `&channel=${channel}` : '';
    return `${scheme}://${host}:${port}/auth/outbound?service=${service}${channelParam}`;
};

const lookupChannel = channel => {
    return (
        {
            [BRANDS.CAPITAL_ONE.toUpperCase()]: BRANDS.CAPITAL_ONE,
            [BRANDS.POST_OFFICE.toUpperCase()]: BRANDS.POST_OFFICE,
            [BRANDS.ASOS]: BRANDS.ASOS,
            [BRANDS.ASOS.toLowerCase()]: BRANDS.ASOS
        }[channel?.toUpperCase()] || BRANDS.CAPITAL_ONE
    );
};

const setMockUrl = url => {
    delete window.location;
    window.location = {
        href: url,
        search: url.substring(url.indexOf('?'))
    };
};

export {
    buttonSubmission,
    onChangeEventForRadio,
    makeRequestHeaders,
    organismProps,
    acceptOnlyNumbersInput,
    acceptOnlyNumericPasteEvent,
    blurTrackerSetter,
    setCSRF,
    getEncryptionKey,
    errorHandling,
    getErrorForWarningText,
    authOutboundURL,
    lookupChannel,
    setMockUrl
};
