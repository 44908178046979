import { BRANDS } from '@cof/plastic-components';

const appendBrandParam = (brand, pathname) => {
    if (brand && brand !== BRANDS.CAPITAL_ONE) {
        return pathname.includes('?')
            ? `${pathname}&channel=${brand}`
            : `${pathname}?channel=${brand}`;
    }
    return `${pathname}`;
};

export default appendBrandParam;
